@import url('../../css/webflow.css');
@import url('../../css/translation.css');
@import url('../../css/ibl-skillspro-v3.webflow.css');
@import url('../../css/normalize.css');
@import url('../../css/custom.css');

.af-view .chat-box.logged-in.hidden {
  display: flex;
}

.dash_nav_item:hover, .dash_nav_item.active{
  border-bottom-color: var(--accent)!important;
}

.d-flex{
  display: flex;
}

.flex-align-center{
  align-items: center;
}

.flex-justify-space-between{
  justify-content: space-between;
}

.d-none{
  display:none;
}

[dir="ltr"] .div-block-161.edit_profile_trigger.home{
  right: 13px;
}

[dir="rtl"] .div-block-161.edit_profile_trigger.home{
  left: 13px;
}

@media screen and (max-width: 767px){
  .full.w-bar .main-content{
    display: flex;
  }
}

@media screen and (max-width: 1170px){
  [dir="ltr"] .full.w-bar .main-content{
    padding-right: 60px;
  }

  [dir="rtl"] .full.w-bar .main-content{
    padding-left: 60px;
  }
}

@media screen and (max-width: 1170px){
  [dir="ltr"] .full.w-bar .right-content{
      left:unset;
  }

  [dir="rtl"] .full.w-bar .right-content{
      right:unset;
  }
}