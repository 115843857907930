
.mini-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
}
.mini-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.mini-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}
.mini-switch-slider:before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
}
input:checked + .mini-switch-slider {
    background-color: var(--accent);
}
input:focus + .mini-switch-slider {
    box-shadow: 0 0 1px var(--accent);
}
input:checked + .mini-switch-slider:before {
    transform: translateX(14px);
}
.mini-switch-slider.round {
    border-radius: 20px;
}
.mini-switch-slider.round:before {
    border-radius: 50%;
}

.no-notification{
    text-align: center;
    margin: 25px;
}

.single-notification-block.read {
    cursor: default !important;
}