[dir="ltr"] .course_inside_menu .second-level-outline{
    padding-left: 30px;
}

[dir="rtl"] .course_inside_menu .second-level-outline{
    padding-right: 30px;
}

.course_inside_menu .second-level-outline div{
    font-weight: normal;
    font-size: 13px;
}

.course_inside_menu .inner.open{
    cursor: pointer;
}

.course_inside_menu .inner.open .inner_text{
    align-items: center;
    display: flex;
}

.secondary_menu{
    padding: 15px 3%;
}

[dir="ltr"] .div-block-630{
    padding-left: 3%;
}

[dir="rtl"] .div-block-630{
    padding-right: 3%;
}

@media screen and (max-width: 480px) {
    .secondary_menu{
        padding: 15px 0px;
    }

    [dir="ltr"] .div-block-630{
        padding-left: 0px!important;
    }

    [dir="rtl"] .div-block-630{
        padding-right: 0px!important;
    }

    [dir="ltr"] .secondary_link{
        padding-left: 0px!important;
    }

    [dir="rtl"] .secondary_link{
        padding-right: 0px!important;
    }
}