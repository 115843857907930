.filter-tag-closer-icon{
    border: 1px solid #6d717f;
    align-items: center;
    opacity: .7;
    cursor: pointer;
    border-radius: 50%;
    justify-content: center;
    width: 13px !important;
    height: 13px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 2px;
}


