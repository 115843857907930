.footer-menu-container{
    width: auto;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.footer-menu-container a{
    text-decoration: unset;
    color: inherit;
    font-size: 14px;
}

.footer-menu-container a:hover{
    text-decoration: underline;
}

.div-block-158{
    height: 100%;
}

[dir="ltr"] .div-block-158{
    margin-left: auto!important;
}

[dir="rtl"] .div-block-158{
    margin-right: auto!important;
}

.copyright-box{
    display: flex;
    height: 100%;
    align-items: center;
}

a.copyright-box{
    cursor: pointer;
    text-decoration: none;
}

a.copyright-box:hover{
    text-decoration: underline;
}

@media screen and (min-width: 1024px){
    .full.w-bar .sk-footer{
        width: calc(100%  - 380px);
    }
}

.sk-footer > .div-block-158{
    margin-left: 3%!important;
    margin-right: 3%!important;
}