@import url('../../css/webflow.css');
@import url('../../css/ibl-skillspro-v3.webflow.css');
@import url('../../css/normalize.css');
@import url('../../css/custom.css');

.af-view .chat-box.logged-in.hidden {
    display: flex !important;
}

.dash_nav_item:hover, .dash_nav_item.active{
    border-bottom-color: var(--accent)!important;
}

.d-flex{
    display: flex;
}

.flex-align-center{
    align-items: center;
}

.flex-justify-space-between{
    justify-content: space-between;
}

.d-none{
    display:none;
}

[dir="ltr"] .div-block-161.edit_profile_trigger.home{
    right: 13px;
}

[dir="rtl"] .div-block-161.edit_profile_trigger.home{
    left: 13px;
}

.p2{
    display: inherit;
}

.p3{
    display: inherit;

}

.p4{
    display: inherit;

}

.p5{
    display: inherit;

}

body {
    color: #6d717f;
    font-family: Hkgrotesk, sans-serif;
    font-size: 16px;
}
.add-role-skills-notification-block{
    padding: 10px;
    background-color: green;
    border-radius: 6px;
    color: #FFF;
}
.add-role-skills-notification-block.error{
    background-color: #e45757;
}