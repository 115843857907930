.html-submenu{
    position: absolute;
    width: 100%;
    top: 70px;
    display: none;
}

[dir="ltr"] .html-submenu{
    left: 80px;
}

[dir="rtl"] .html-submenu{
    right: 80px;
}

.html-submenu iframe{
    border-radius: 10px;
    width: 80%;
    height: 420px;
    border: 0.3px solid grey;
    z-index: 999999;
    position: relative;
}

.dash_nav_item:hover > .html-submenu{
    display: block;
}

@media screen and (min-width: 769px) {
    .full.w-bar .nav > .p_menu{
        margin-left: 3% !important;
        margin-right: 3% !important;
    }
}

@media screen and (max-width: 768px) {
    [dir="ltr"] .mobile_nav_trigger {
        padding: 5px 0px 0px 10px!important;
    }

    [dir="rtl"] .mobile_nav_trigger {
        padding: 5px 10px 0px 0px!important;
    }
}


.notification-stat {
    background-color: #e74d4d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -5px;
    left: 8px;
    color: white;
    font-size: 10px;
    font-weight: bold;
}