.banner-btn {
    background-color: transparent;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    max-width: 230px;
    height: 20px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 16px;
    display: flex;
    position: absolute;
}

[dir="ltr"] .banner-btn {
    right: 0;
}

[dir="rtl"] .banner-btn {
    left: 0;
}

.secondary_options .last-element {
    align-self: flex-end;
}