/* styles.css */

.w-button.disabled {
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;
}

.accordion.js-accordion {
  margin-bottom: 80px !important;
}