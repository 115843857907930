ul {
    list-style: none !important;
}

[dir="ltr"] ul {
    padding-left: 0 !important;
}

[dir="rtl"] ul {
    padding-right: 0 !important;
}

.role_div.selected {
    background-color: #d3d3d3; /* Highlight color */
    /*border: 2px solid #000; !* Optional: Add a border to emphasize selection *!*/
}
